import type { MetaFunction } from "react-router";
import { ClientOnly } from "remix-utils/client-only";

import { Skeleton } from "@/components/atoms/Loading/Skeleton";
import { App } from "../../src/AppClient";

export default function Component() {
  return (
    <ClientOnly fallback={<Skeleton repeat={5} />}>{() => <App />}</ClientOnly>
  );
}

export const meta: MetaFunction = () => {
  const title = "GuideStack";

  return [
    {
      title,
    },
    {
      property: "og:title",
      content: title,
    },
  ];
};
